.footer {
  display: flex;
  justify-content: center;

  width: 100%;

  margin-top: 20px;
  margin-bottom: 10px;

  .teamSection {
    font-size: 18px;

    .plzContactTxt {
      display: flex;
      justify-content: center;
    }

    .teamMemberContainer {
      display: flex;
      justify-content: center;

      .roleTxt {
        margin-right: 5px;
      }

      .nameTxt {
        text-decoration: underline;
      }
    }
  }
}
