$headerHeight: 50px;

$backgroundColor: #ffae00;
$textColor: #351b64;

.PDSNavbar {
  display: flex;
  align-items: center;

  background-color: $backgroundColor;
  color: $textColor;

  height: $headerHeight;

  .backButton {
    flex-direction: start;

    text-decoration: none;
    color: $textColor;

    font-size: 30px;
    font-weight: 600;

    margin-left: 10px;
  }

  .titleTextContainer {
    display: flex;
    justify-content: center;

    flex: 1;

    .titleText {
      font-size: 25px;
      font-weight: 500;
    }
  }
}
