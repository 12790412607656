$main-color: #7111ba;
$main-text-color: #fef7ff;

.terrenoFrancisLeany {
  .header {
    display: flex;
    align-items: center;

    background-color: $main-color;
    color: $main-text-color;

    top: 0px;

    width: 100vw;
    height: 50px;

    font-size: 35px;

    .backButton {
      color: white;
      text-decoration: none;

      flex-direction: start;

      font-size: 30px;
      font-weight: 600;

      margin-left: 10px;
    }

    .titleTextContainer {
      display: flex;
      justify-content: center;

      flex: 1;
    }
  }

  /* ___________________________________________________ */

  .main {
    display: flex;
    justify-content: center;
    // margin: 8px;

    .imagenes {
      display: flex;

      .img-container {
        margin: 5px;

        .imagen {
          height: 400px;
          width: 500px;
        }

        .imagen:hover {
          transform: scale(1.1);
          position: relative;
          transition: all 300ms ease-in-out;
        }
      }
    }
  }

  .container2 {
    display: flex;
    justify-content: center;
    .precio {
      text-align: center;

      background-color: $main-color;
      color: $main-text-color;

      width: 100%;

      font-size: 40px;
    }
  }

  .icon-whatsapp {
    position: relative;
    font-size: 40px;
    display: flex;
    justify-content: center;
    right: 30px;
    bottom: 540px;
    position: fixed;
  }
  .icon-whatsapp:hover {
    transform: scale(1.1);
  }

  .icon-map {
    position: relative;
    font-size: 40px;
    display: flex;
    justify-content: center;
    right: 35px;
    bottom: 490px;
    position: fixed;
  }

  .icon-map:hover {
    transform: scale(1.1);
  }
}

@media only screen and (max-width: 900px) {
  .terrenoFrancisLeany {
    .header {
      display: flex;
      justify-content: center;
      align-items: center;

      background-color: $main-color;
      color: $main-text-color;
      // text-shadow: 3px 3px 3px pink;

      top: 0px;

      width: 100vw;
      height: 50px;

      font-size: 30px;
    }

    .main {
      display: flex;
      justify-content: center;
      // margin: 8px;

      .imagenes {
        // display: flex;
        display: grid;
        grid-template-columns: 1fr;

        .img-container {
          margin: 5px;

          .imagen {
            height: 200px;
            width: 250px;
          }

          .imagen:hover {
            transform: scale(1.1);
            position: relative;
            transition: all 300ms ease-in-out;
          }
        }
      }
    }

    .container2 {
      display: flex;
      justify-content: center;
      .precio {
        text-align: center;

        background-color: $main-color;
        color: $main-text-color;

        width: 100vw;

        font-size: 40px;
      }
    }

    .icon-whatsapp {
      position: relative;
      font-size: 40px;
      display: flex;
      justify-content: center;
      right: 30px;
      bottom: 540px;
      position: fixed;
    }
    .icon-whatsapp:hover {
      transform: scale(1.1);
    }

    .icon-map {
      position: relative;
      font-size: 40px;
      display: flex;
      justify-content: center;
      right: 35px;
      bottom: 490px;
      position: fixed;
    }

    .icon-map:hover {
      transform: scale(1.1);
    }
  }
}
