.homePage {
  .companyCardsContainer {
    display: flex;
    justify-content: center;

    .companyCardsWrapper {
      width: 95%;

      display: grid;
      grid-gap: 0.5em;
      grid-template-columns: repeat(auto-fill, minmax(245px, 1fr));
    }
  }
}
