@import "../../ColorLibrary.scss";

$cardHeight: 300px;
$cardWidth: 240px;

$logoImgHeight: $cardHeight/2 - 10px;

// $logoImgHeight: $cardHeight/2;

$availableTextSize: 22px;

.companyCardContainer {
  display: flex;
  justify-content: center;

  .logoCompanyCard {
    background-color: $offWhite;
    border: 1px solid rgb(228, 228, 228);
    box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.1);
    // box-shadow: 5px 10px 10px red;

    height: $cardHeight;
    width: $cardWidth;

    border-radius: 7px;

    text-decoration: none;

    margin: 5px;

    .topSection {
      display: flex;
      justify-content: center;
      align-items: center;

      border-radius: 7px 7px 0px 0px;

      height: $cardHeight / 2;

      .companyLogoImg {
        height: $logoImgHeight;
      }

      .invertedCompanyLogoImg {
        height: $logoImgHeight;
        filter: invert(1);
      }

      .noLogoNameOfCompanyText {
        text-align: center;
        font-size: 25px;
      }
    }

    .bottomSection {
      text-align: center;

      .companyName {
        font-size: 26px;
        font-weight: 500;

        color: $black;

        margin-left: 5px;
      }

      .isAvailableText {
        font-size: $availableTextSize;
        font-weight: 400;

        color: $secondaryColor;

        margin-left: 5px;
      }
      .notAvailableText {
        font-size: $availableTextSize;
        font-weight: 400;

        color: rgb(197, 38, 38);
        margin-left: 5px;
      }
    }
  }
}
