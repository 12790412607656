$numberBoxWidth: 100px;

.lotsTable {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;

  box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.1);

  max-width: 400px;
  min-width: 150px;

  .tableHeader {
    position: sticky;
    top: 0px;
    margin: 0 0 0 0;
    .tableHeaderRow {
      .tableHeaderBox {
        text-align: center;

        border: 1px solid rgb(93, 90, 90);

        padding: 8px 0px;
      }

      .headerNumberBox {
        width: $numberBoxWidth;
      }
    }
  }

  .tableBody {
    .tableBodyRow {
      .tableBodyBox {
        border: 1px solid rgb(93, 90, 90);
        padding: 5px 0px;

        .tableBodyBoxText {
          margin-left: 5px;
        }
      }

      .numberValueBox {
        width: $numberBoxWidth;
      }

      .statusValuesBox {
        width: 200px;
      }
    }

    .tableBodyRow:nth-child(even) {
      background-color: rgb(214, 214, 214);
    }
    .tableBodyRow:hover:nth-child(even) {
      background-color: rgb(184, 184, 184);
      transition: 200ms;
    }

    .tableBodyRow:nth-child(odd) {
      background-color: rgb(236, 236, 236);
    }
    .tableBodyRow:hover:nth-child(odd) {
      background-color: rgb(206, 206, 206);
      transition: 200ms;
    }
  }
}
