$main-color: #e5583f;
$main-text-color: #fef7ff;
.theSpringcontent {
  .header {
    display: flex;
    align-items: center;

    background-color: $main-color;
    color: $main-text-color;
    // text-shadow: 3px 3px 3px pink;

    top: 0px;

    width: 100%;
    height: 80px;

    font-size: 30px;

    .backButton {
      color: white;
      text-decoration: none;

      flex-direction: start;

      font-size: 30px;
      font-weight: 600;
    }

    .contentlogoandtext {
      display: flex;
      justify-content: center;
      align-items: center;
      // flex: 1;

      .contentimg {
        .img {
          max-width: 60px;
        }
      }
      .titleTextContainer {
        display: flex;
        justify-content: center;
        align-items: center;

        margin-right: -22%;

        .titleFirstText {
          font-weight: 500;
          font-size: 0.9em;
        }

        .titleSecText {
          font-weight: 500;
          font-size: 0.5em;
        }
      }
    }
  }

  .imagenes {
    display: flex;
    justify-content: center;
    align-items: center;

    .img-container {
      margin: 5px;

      .imagen {
        object-fit: cover;
        width: 100%;
      }

      .imagen:hover {
        transform: scale(1.1);
        position: relative;
        transition: all 300ms ease-in-out;
      }
    }
  }

  .main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    margin-bottom: 8px;

    .imagenes {
      display: flex;

      justify-content: center;
      align-items: center;

      .img-container {
        .imagen {
          // height: 500px;
          width: 100%;
        }

        .imagen:hover {
          transform: scale(1.1);
          position: relative;
          transition: all 300ms ease-in-out;
        }
      }
    }

    .contentLink {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 5% 0 15% 0;
      .btn-link {
        font-size: 2.5em;
        background-color: #ff6a50;
        width: 100%;
        border-radius: 5px;
        border: none;

        a {
          text-decoration: underline;
          color: black;
          font-weight: 700;
        }
      }
    }

    .contentInfo {
      display: flex;
      text-align: center;
      justify-content: center;
      margin: 5px;
      .textInfo {
        font-weight: 500;
      }
    }
  }

  .footer {
    padding: 0;
    margin: 0;

    position: relative;
    bottom: 0;
    height: 50px;
    background: $main-color;
    color: white;
    text-align: center;
    left: 0;
    width: 100%;
  }
}

@media only screen and (max-width: 900px) {
  .theSpringcontent {
    .header {
      display: flex;
      align-items: center;

      background-color: $main-color;
      color: $main-text-color;
      // text-shadow: 3px 3px 3px pink;

      top: 0px;

      width: 100vw;
      height: 80px;

      font-size: 30px;

      .backButton {
        color: white;
        text-decoration: none;

        flex-direction: start;

        font-size: 30px;
        font-weight: 600;

        margin-left: 10px;
        margin-right: 12%;
      }

      .titleTextContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-right: -22%;
        // flex: 1;

        .titleFirstText {
          font-weight: 500;
          font-size: 0em;
        }

        .titleSecText {
          font-weight: 500;
          font-size: 0.5em;
        }
      }
    }

    .imagenes {
      display: flex;

      .img-container {
        margin: 5px;

        .imagen {
          object-fit: cover;
          width: 100%;
        }

        .imagen:hover {
          transform: scale(1.1);
          position: relative;
          transition: all 300ms ease-in-out;
        }
      }
    }

    .main {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      margin-bottom: 8px;

      .imagenes {
        display: flex;

        justify-content: center;
        align-items: center;

        .img-container {
          .imagen {
            // height: 500px;
            width: 100%;
          }

          .imagen:hover {
            transform: scale(1.1);
            position: relative;
            transition: all 300ms ease-in-out;
          }
        }
      }

      .contentLink {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 5% 0 15% 0;
        .btn-link {
          font-size: 2.5em;
          background-color: #ff6a50;
          width: 100%;
          border-radius: 5px;
          border: none;

          a {
            text-decoration: underline;
            color: black;
            font-weight: 700;
          }
        }
      }

      .contentInfo {
        display: flex;
        text-align: center;
        justify-content: center;
        margin: 5px;
        .textInfo {
          font-weight: 500;
        }
      }
    }

    .footer {
      padding: 0;
      margin: 0;

      position: relative;
      bottom: 0;
      height: 50px;
      background: $main-color;
      color: white;
      text-align: center;
      left: 0;
      width: 100%;
    }
  }
}
