$main-color: #e5583f;
$main-text-color: #fef7ff;

.TSNavBar {
  .header {
    display: flex;
    align-items: center;

    background-color: $main-color;
    color: $main-text-color;

    top: 0px;

    width: 100%;
    height: 60px;

    font-size: 35px;

    .backButton {
      color: white;
      text-decoration: none;

      flex-direction: start;

      font-size: 30px;
      font-weight: 600;

      margin-left: 10px;

      .backText {
      }
    }

    .contentlogoandtext {
      display: flex;
      justify-content: center;
      align-items: center;
      // // flex-direction: column;
      // margin: 100px;
      // flex: 1;

      .contentimg {
        .img {
          max-width: 60px;
        }
      }
      .titleTextContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        margin-right: -22%;

        .titleFirstText {
          font-weight: 500;
          font-size: 0.9em;
        }

        .titleSecText {
          font-weight: 500;
          font-size: 0.5em;
        }
      }
    }
  }

  @media only screen and (max-width: 900px) {
    .header {
      background-color: $main-color;
      color: $main-text-color;

      top: 0px;

      width: 100vw;
      height: 80px;

      font-size: 35px;

      .backButton {
        color: white;
        text-decoration: none;

        flex-direction: start;

        font-size: 30px;
        font-weight: 600;

        .backText {
          font-size: 0.5em;
        }
      }

      .contentlogoandtext {
        display: flex;
        justify-content: center;
        align-items: center;
        // flex: 1;

        .contentimg {
          .img {
            max-width: 60px;
          }
        }
        .titleTextContainer {
          display: flex;
          justify-content: center;
          align-items: center;

          .titleFirstText {
            font-weight: 500;
            font-size: 0.5em;
          }

          .titleSecText {
            font-weight: 500;
            font-size: 0.4em;
          }
        }
      }
    }
  }
}
