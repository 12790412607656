@import url("https://fonts.googleapis.com/css2?family=Anton&family=Montserrat:ital@1&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Anton&family=Montserrat:ital@1&display=swap");

@import "../../ColorLibrary.scss";

$cardHeightSmall: 550px;
$cardWidthSmall: 100%;

$cardHeightLarge: 500px;

$cardBgColor: rgb(240, 240, 240);
$inputShadow: rgb(233, 233, 233);

$cardBgColorSmall: rgb(255, 255, 255);
$inputShadowSmall: rgb(243, 243, 243);

.contactPage {
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (min-width: 800px) {
    margin-top: 40px;
  }

  .contactCardLarge {
    display: none;
  }

  .contactCard {
    height: $cardHeightSmall;
    width: $cardWidthSmall;

    margin: 5px;

    .topSectionContainer {
      .titleContainer {
        display: flex;
        justify-content: space-between;

        font-weight: 500;

        .titleText {
          font-size: 20px;
          margin-right: 20px;
        }

        .infoPieceContainer {
          display: flex;
          align-items: center;

          font-size: 15px;
          margin-right: 6px;

          .icon {
            color: black;
            margin-right: px;

            text-decoration: none;

            .text {
              margin-left: 5px;
            }
          }

          // .text {
          //   display: flex;
          //   align-items: center;
          // }
        }
      }

      .reachUsText {
        display: flex;

        color: rgb(126, 126, 126);

        font-weight: 400;

        font-size: 14px;

        .emailText {
          color: black;

          font-weight: 500;
          text-decoration: underline;

          margin-left: 5px;
        }
      }
    }

    .allInputsContainer {
      .formWrapper {
        height: 100%;

        .inputContainer {
          margin-top: 5px;

          .inputTitle {
            font-size: 16px;
          }

          .formInput {
            width: 96.5%;

            background-color: transparent;
            // background-color: rgb(245, 245, 245);

            background-image: linear-gradient(
              to bottom,
              $cardBgColorSmall,
              $cardBgColorSmall,
              $inputShadowSmall
            );

            border: transparent;
            border-bottom: 2px solid rgb(47, 47, 47);
            border-radius: 0px;

            padding-top: 5px;
          }

          .fromInputSmall {
            height: 40px;
          }

          .fromInputTextArea {
            height: 80px;
          }

          .formSubmitBtn {
            background-color: $bronze;
            color: white;

            font-size: 15px;

            border: none;
            border-radius: 5px;

            width: 100%;
            height: 40px;
          }
        }
      }
    }

    .iconsSection {
      margin-top: 0px;

      .alsoReachUsHereText {
        text-align: center;
        font-size: large;
        font-weight: 500;
      }

      .iconsContainer {
        display: flex;
        justify-content: space-evenly;

        .iconWhatsapp {
          a {
            cursor: pointer;
            font-size: 2.3em;
            color: #4fce5d;
          }
        }

        .iconMessenger {
          a {
            cursor: pointer;
            font-size: 2.3em;
            color: #0078ff;
          }
        }
      }
    }
  }

  @media only screen and (min-width: 800px) {
    .contactCard {
      display: none;
    }

    .contactCardLarge {
      display: grid;
      grid-template-columns: 1fr 2fr;

      background-color: $cardBgColor;

      height: $cardHeightLarge;
      width: 800px;

      border: 1px solid rgb(197, 197, 197);
      border-radius: 0.3em;

      margin: 10px;

      .leftSide {
        height: 100%;

        .leftImage {
          height: $cardHeightLarge;
          width: 350px;

          object-fit: cover;
          object-position: -182px 0px;

          border-top-left-radius: 0.3em;
          border-bottom-left-radius: 0.3em;
        }
      }

      .rightSide {
        height: $cardHeightLarge;

        padding-left: 10px;
        padding-right: 10px;

        .topSectionContainer {
          .titleText {
            font-size: 28px;
            font-weight: 500;

            margin-top: 5px;
          }

          .reachUsText {
            color: rgb(126, 126, 126);

            font-weight: 400;

            .emailText {
              color: black;
              font-weight: 500;
              text-decoration: underline;
            }
          }
        }

        .extraInformationContainer {
          display: grid;
          grid-template-columns: 1fr 1fr;

          margin-top: 10px;
          margin-bottom: 10px;

          .infoPieceContainer {
            display: flex;

            .icon {
              color: black;

              margin-right: 5px;
            }
          }
        }

        .iconsSection {
          display: flex;
          justify-content: space-evenly;

          margin-bottom: 30px;

          .icon-whatsapp {
            a {
              cursor: pointer;
              font-size: 2.3em;
              color: #4fce5d;
            }
          }

          .icon-messenger {
            a {
              cursor: pointer;
              font-size: 2.3em;
              color: #0078ff;
            }
          }
        }

        .allInputsContainer {
          .formWrapper {
            height: 100%;

            .inputContainer {
              margin-top: 5px;

              .inputTitle {
                font-size: 16px;
              }

              .formInput {
                width: 99%;

                background-color: transparent;
                // background-color: rgb(245, 245, 245);

                background-image: linear-gradient(
                  to bottom,
                  $cardBgColor,
                  $cardBgColor,
                  $inputShadow
                );

                border: transparent;
                border-bottom: 2px solid rgb(46, 46, 46);

                padding-top: 5px;
              }

              .fromInputSmall {
                height: 40px;
              }

              .fromInputTextArea {
                height: 80px;
              }

              .formSubmitBtn {
                background-color: $bronze;
                color: white;

                font-size: 15px;

                border: none;
                border-radius: 5px;

                width: 100%;
                height: 40px;
              }
            }
          }
        }
      }
    }
  }
}

/* __________________________________________________________ */

// .content-contact-page-wrapper {
//   font-family: "Montserrat", sans-serif;

//   background-color: rgb(245, 245, 245);

//   @media screen {
//     max-width: 575px;
//   }

//   .header-contact-page-wrapper {
//     display: flex;
//     justify-content: space-between;
//     background-color: black;
//     color: white;
//     align-items: center;

//     .back-arrow {
//       font-size: 2em;
//       margin-left: 1em;
//     }

//     .contact {
//       display: block;
//       font-size: 2em;
//       margin-block-start: 0.67em;
//       margin-block-end: 0.67em;
//       font-weight: bold;
//       margin-right: 4em;
//     }
//   }

//   .left-column-contact-page {
//     display: flex;
//     justify-content: center;

//     .bullet-point-group {
//       margin-top: 2em;

//       .icon {
//         display: grid;
//         grid-template-columns: 1fr;
//       }
//     }
//   }

//   .rigth-column-contact-page {
//     .form-wrapper {
//       display: grid;
//       grid-template-columns: 1fr;
//       padding: 42px;

//       input {
//         color: gray;
//         padding: 5px 0px;
//         width: 100%;
//         border-top: 0px;
//         border-right: 1px solid black;
//         border-left: 0px;
//         border-bottom: 1px solid black;
//         background-color: transparent;
//         transition: 0.5s all ease-in-out;
//         font-size: 1em;
//         margin-bottom: 21px;
//       }

//       textarea {
//         color: gray;
//         padding: 5px 0px;
//         height: 4em;
//         width: 100%;
//         border-top: 0px;
//         border-right: 1px solid black;
//         border-left: 0px;
//         border-bottom: 1px solid black;
//         background-color: transparent;
//         transition: 0.5s all ease-in-out;
//         font-size: 1.5em;
//         margin-bottom: 1px;
//       }

//       button {
//         color: black;
//         width: 100%;
//         background-color: lightgray;
//         transition: 0.5s all ease-in-out;
//         font-size: 1em;
//       }
//     }
//   }

//   .footer-contact-page {
//     .icon-whatsapp {
//       position: fixed;
//       z-index: 999;
//       left: 100px;
//       bottom: 2em;

//       a {
//         cursor: pointer;
//         font-size: 3em;
//         color: green;
//       }
//     }

//     .icon-messenger {
//       position: fixed;
//       z-index: 999;
//       right: 100px;
//       bottom: 2em;

//       a {
//         cursor: pointer;
//         font-size: 3em;
//         color: blueviolet;
//       }
//     }
//   }
// }
