@import url("https://fonts.googleapis.com/css2?family=Anton&family=Montserrat:ital@1&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Anton&family=Montserrat:ital@1&display=swap");

@import "../../ColorLibrary.scss";

.Cactus-acres-About-page {
  .navbar {
    position: fixed;
    width: 100%;
    margin-top: 0;
    padding-top: 0;
    z-index: 1;
  }

  .AboutPage {
    font-family: "Montserrat", sans-serif;

    margin: 0px 10px 10px 10px;

    .hero-section-about {
      color: black;
      padding-top: 15%;
      @media (min-width: 768px) {
        padding-top: 5%;
      } //media

      .about-us {
        font-family: "Abril Fatface", cursive;
        font-size: 2.5em;

        color: black;

        @media (min-width: 768px) {
          font-size: 2.5em;
          text-align: center;
        }
      }

      .informationSection {
        display: flex;
        justify-content: center;

        flex-wrap: wrap;

        .cardContainer-mobile {
          display: grid;

          margin-bottom: 10px;

          border: 1px solid rgb(170, 170, 170);

          .infoSection {
            border-bottom-left-radius: 7px;

            .titleText {
              font-size: 22px;

              margin-left: 5px;

              margin: 0px 5px 5px 5px;
            }

            .descriptionText {
              margin: 0px 5px 5px 5px;
            }
          }

          .imageContainer {
            .infoImage {
              width: 100%;
            }
          }
        }

        .cardContainer-1 {
          display: none;
        }

        .cardContainer-2 {
          display: none;
        }

        @media (min-width: 768px) {
          .cardContainer-mobile {
            display: none;
          }

          .cardContainer-1 {
            display: grid;

            grid-template-columns: 1fr 1fr;

            margin-bottom: 30px;

            width: 90%;

            max-width: 1200px;

            .infoSection {
              border-right: 1px solid $greyBorder;
              border-bottom: 1px solid $greyBorder;

              border-bottom-right-radius: 7px;

              .titleText {
                font-size: 35px;

                margin-left: 5px;

                margin: 0px 5px 5px 5px;

                @media (max-width: 960px) {
                  font-size: 28px;
                }
              }

              .descriptionText {
                font-size: 20px;

                margin: 0px 5px 5px 5px;

                @media (max-width: 960px) {
                  font-size: 18px;
                }
              }
            }

            .imageContainer {
              .infoImage {
                width: 100%;
              }
            }
          }

          .cardContainer-2 {
            display: grid;

            grid-template-columns: 1fr 1fr;

            margin-bottom: 30px;

            width: 90%;

            max-width: 1200px;

            .infoSection {
              border-left: 1px solid $greyBorder;
              border-bottom: 1px solid $greyBorder;

              border-bottom-left-radius: 7px;

              .titleText {
                font-size: 35px;

                margin-left: 5px;

                margin: 0px 5px 5px 5px;

                @media (max-width: 960px) {
                  font-size: 30px;
                }
              }

              .descriptionText {
                font-size: 20px;

                margin: 0px 5px 5px 5px;

                @media (max-width: 960px) {
                  font-size: 18px;
                }
              }
            }

            .imageContainer {
              .infoImage {
                width: 100%;
              }
            }
          }
        }
      }

      /* ___________________________________ */
    } //hero-section-about
  } //AboutPage
} //Cactus-acres-About-page
