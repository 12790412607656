@import url("https://fonts.googleapis.com/css2?family=Anton&family=Montserrat:ital@1&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Anton&family=Montserrat:ital@1&display=swap");

@import "../../ColorLibrary.scss";

$navbarHeight: 60px;

$hamburgerTransitionTime: 300ms;
$hamburgerIconsMarginRight: 0px;

.cactusAcresNavbar {
  z-index: 1;
}

@media only screen and (max-width: 700px) {
  .cactusAcresNavbar {
    position: fixed;
    top: 0;

    display: flex;

    background-color: $offBlack;
    color: #ffffff;

    height: $navbarHeight;
    width: 100%;

    .backButton {
      display: none;
    }

    .logoContainer {
      display: flex;
      align-items: center;

      margin-left: 5px;
      .logoImg {
        filter: invert(1);

        height: $navbarHeight - 10;
      }
    }

    .textContainer {
      display: flex;
      align-items: center;
      justify-content: center;

      flex: 1;

      text-decoration: none;
      color: white;

      .titleTextMobile {
        font-family: "Amatic SC";

        font-size: 25px;
        font-weight: 500;
      }

      .titleTextLargeScreen {
        display: none;
      }
    }

    .linksContainer {
      display: none;
    }

    .changeLanguageBtnContainer {
      display: none;
    }

    .hamburgerContainer {
      display: flex;
      align-items: center;

      width: 30px;

      padding-right: 5px;

      .hamburgerIcon {
        font-size: 25px;
      }
      .hamburgerIcon:hover {
        transition: $hamburgerTransitionTime;
      }

      .closeDropdownIcon {
        font-size: 35px;
        font-weight: 500;
      }

      .closeDropdownIcon:hover {
        transition: $hamburgerTransitionTime;
      }
    }
  }
  .pushContentDown {
    height: $navbarHeight;
  }

  .dropdown {
    position: absolute;
    top: 58px;

    background-color: $offBlack;
    border: 1px solid rgb(87, 87, 87);
    border-radius: 7px;

    width: 150px;

    transform: translateX(-102%);

    padding: 1rem;

    overflow: hidden;

    .activePageLink {
      display: flex;
      align-items: center;

      text-decoration: none;
      color: rgb(255, 255, 255);
      background-color: #3e3e42;
      height: 40px;

      border-radius: 7px;

      padding: 0.5rem;

      .menuLink {
        font-size: large;
        font-weight: 600;
      }

      .changeLanguageBtnPhone {
        text-align: start;

        background-color: red;
        color: white;
        border: none;
        font-size: large;

        width: 100%;
        padding: 0.5rem;
      }
    }

    .dropdownPageLink {
      display: flex;
      align-items: center;

      text-decoration: none;
      color: white;

      height: 40px;

      border-radius: 7px;

      padding: 0.5rem;

      .menuLink {
        font-size: large;
      }

      .changeLanguageBtnPhone {
        text-align: start;

        background-color: red;
        color: white;
        border: none;

        font-size: large;

        width: 100%;
        padding: 0.5rem;
      }
    }

    .dropdownPageLink:hover {
      transition: 300ms;
      background-color: #2a2a2c;
    }

    .dropdownItemBtn {
      display: flex;
      align-items: center;

      text-decoration: none;
      color: white;

      height: 50px;

      border-radius: 7px;

      padding: 0.5rem;

      .changeLanguageBtnPhone {
        // background-color: red;
        background-color: transparent;
        color: white;
        border: none;

        width: 100%;
        padding: 0.5rem;

        .btnText {
          text-align: start;

          font-size: large;

          margin-left: -8px;
        }
      }
    }

    .dropdownItemBtn:hover {
      transition: 300ms;
      background-color: #2a2a2c;
    }
  }
}

@media only screen and (min-width: 700px) {
  .cactusAcresNavbar {
    position: fixed;
    top: 0;

    display: flex;

    background-color: $offBlack;
    color: white;

    height: $navbarHeight;
    width: 100%;

    .backButton {
      display: flex;
      align-items: center;

      text-decoration: none;
      color: white;

      font-size: 20px;

      margin: 0px 10px;

      .backBtnFont {
      }

      .backText {
      }
    }

    .logoContainer {
      display: flex;
      align-items: center;

      margin-left: 5px;
      .logoImg {
        filter: invert(1);

        height: $navbarHeight - 10;
      }
    }

    .textContainer {
      display: flex;
      align-items: center;

      width: 100px;

      margin-left: 10px;

      text-decoration: none;
      color: white;

      .titleTextMobile {
        display: none;

        font-family: "Amatic SC";

        font-size: 20px;
        font-weight: 500;
      }

      .titleTextLargeScreen {
        font-family: "Amatic SC";
        font-size: 20px;

        text-decoration: none;
        color: white;

        .titleTextPiece {
          display: flex;
          justify-content: center;
        }

        .textPiece-cactus {
          font-size: 20px;
        }

        .textPiece-acres {
          font-size: 16px;
        }
      }
    }

    .linksContainer {
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      width: 50%;
      height: $navbarHeight;

      .allLinks {
        display: flex;
        flex: 1;
      }

      .activeLink {
        padding: 5px 10px;
        margin: 0px 10px;

        border-radius: 7px;
        background: #3e3e42;
        text-decoration: none;
        color: rgb(255, 255, 255);

        .linkText {
          // font-family: "Amatic SC";
          font-size: 20px;
        }
      }

      .headerLink {
        padding: 5px 10px;
        margin: 0px 10px;

        border-radius: 7px;

        text-decoration: none;
        color: white;

        .linkText {
          // font-family: "Amatic SC";
          font-size: 20px;
        }
      }

      .headerLink:hover {
        background-color: #2a2a2c;
      }
    }

    .rightSection {
      position: absolute;
      right: 0;

      height: 100%;

      margin-right: 10px;

      .changeLanguageBtnContainer {
        display: flex;
        align-items: center;

        height: 100%;

        .changeLanguageBtn {
          background-color: transparent;
          color: white;

          border: none;
          border-radius: 7px;

          font-size: 20px;

          padding: 5px 10px;
        }

        .changeLanguageBtn:hover {
          background-color: #2a2a2c;
        }
      }
    }

    .hamburgerContainer {
      display: none;
    }
  }

  .dropdown {
    display: none;
  }

  .pushContentDown {
    height: $navbarHeight;
  }
}
