@import "../../ColorLibrary.scss";

$headerHeight: 50px;

.lebaronVecindadHeader {
  background-image: linear-gradient(
    to right,
    rgb(12, 12, 12),
    $mainColor,
    $mainColor,
    $mainColor,
    rgb(12, 12, 12)
  );
  background-color: $mainColor;

  color: $white;

  height: $headerHeight;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-evenly;

  .content-computer {
    display: flex;
    align-items: center;

    .titleText {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 25px;
      font-weight: 100;

      @media (max-width: 800px) {
        display: flex;
        font-size: 1.2em;
        font-weight: 100;
      }
    }

    .changeLanguageContainer {
      display: flex;
      position: absolute;
      right: 0;
      margin-right: 10px;

      @media (max-width: 800px) {
        display: none;
      }
    }
  }

  .content-movile {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    .button-burgerMenu {
      display: none;

      @media (max-width: 800px) {
        display: flex;
        font-size: 2em;
      }
    }

    .lebaronVecindadMenu {
      position: absolute;
      top: 56px;
      width: 150px;
      transform: translateX(-25%);

      @media (min-width: 600px) {
        display: none;
      }

      .changeLanguageContainerMenu {
        background-color: black;
        color: white;
        overflow: hidden;
        border-radius: 5px;

        padding: 5px;
      }
    }
  }
}
