@import "../Header/Header.scss";

.mainChangeLanguageBtn {
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: transparent;
  color: white;
  border: 1px solid transparent;

  border-radius: 7px;

  height: $headerHeight / 1.2;

  .btnText {
    font-size: 16px;
  }
}

.mainChangeLanguageBtn:hover {
  background-color: #2a2a2c;
  transition: 300ms;
}
